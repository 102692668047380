import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Grid } from '@material-ui/core';
import { SectionHeader } from 'components/molecules';

const WhoWeAre = ({ className, ...rest }: ViewComponentProps): JSX.Element => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <div className={className} {...rest}>
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Тарифы Промо"
            subtitle="Бонус за пользователя состоит из двух частей:"
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title=""
            subtitle="
            Первая часть - 10⭐️
            Зачисляется когда пользователь регистрируется (еще не указав местоположение) и находит вас в поиске.
            "
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title=""
            subtitle="
            Вторая часть - 100⭐️ / 50⭐️
            Зачисляется когда пользователь подтверждает свое местоположение (в поиске). Если он оказался в радиусе 50 км от центра большого города (бонус - 100 звезд) или среднего (бонус - 50 звезд) города. Если он оказался далеко от больших или средних городов, то бонус будет 0!
            "
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title=""
            subtitle="
            Большие Города - с населением > 1 миллиона человек - 100⭐️
            (Москва, Санкт-Петербург, Новосибирск, Екатеринбург, Казань, Нижний Новгород, Челябинск, Самара, Омск, Ростов-на-Дону, Уфа, Красноярск, Воронеж, Пермь, Волгоград,
            Алматы, Бишкек, Киев)
            "
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title=""
            subtitle="
            Средние Города - с населением от 500тыс до 1 миллиона человек - 50⭐️
            (Краснодар, Саратов, Тюмень, Тольятти, Ижевск, Барнаул, Ульяновск, Иркутск, Хабаровск, Ярославль, Владивосток, Махачкала, Томск, Оренбург, Кемерово, Новокузнецк, Рязань, Наб. Челны, Астрахань, Пенза, Киров, Липецк, Балашиха)
            "
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title=""
            subtitle="
            Оплачиваются только реальные живые пользователи в возрасте от 18 до 28 лет!
            Каждый пользователь оплачивается после завершения регистрации и проверки админом (если пользователь не общался, расположен слишком близко к пригласившему или другим приглашенным, зарегистрировался с того же устройства и т.п. админ может забраковать пользователя и не платить за него бонус. Причина будет объяснена в чате или отображена в приложении).
            "
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
          <SectionHeader
            title="Как заработать еще?"
            subtitle="Чтобы узнать о других тарифах и способах заработка, добавляйтесь к нам в промо группу:"
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid>
        {/* <Grid item xs={12} sm={6} data-aos="fade-up">
          <SectionHeader
            title="Что означает СтарЧат?"
            subtitle="Приложение названо в честь его главного механизма - звезд, которыми людим обмениваются во время общения. Имменно это и делает его таким уникальным!"
            disableGutter
            align="left"
            subtitleProps={{
              variant: 'body1',
              color: 'textPrimary',
            }}
          />
        </Grid> */}
      </Grid>
    </div>
  );
};

export default WhoWeAre;
